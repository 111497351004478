import { Messenger } from '@/components/Messenger';
import {
  MessengerActiveRoomContext,
  useMessengerActiveRoomContext,
} from '@/contexts/MessengerActiveRoomContext';
import { MessengerContext, MessengerStates } from '@/contexts/MessengerContext';
import { SpinnerIcon } from '@/icons';
import { Disclosure } from '@headlessui/react';
import { ClipboardDocumentIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { useContext, useEffect } from 'react';

export function MessengerScene() {
  const context = useContext(MessengerContext);
  const activeRoomContext = useMessengerActiveRoomContext(context);

  useEffect(() => {
    context.start();
    return () => context.stop();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- start on first render, stop when leave
  }, []);

  function renderMessengerState() {
    switch (context.state) {
      case MessengerStates.STARTING:
        return (
          <div className="grow flex flex-col items-center justify-center gap-8 p-5 rounded-2.5xl bg-gray-10 text-midnight-40">
            <SpinnerIcon className="w-8 h-8 animate-spin fill-current" />
            <span className="sr-only">Loading...</span>
          </div>
        );
      case MessengerStates.START_SUCCESS:
        return <Messenger />;
      case MessengerStates.START_FAILED:
        return (
          <div className="grow flex flex-col items-center justify-center gap-8 p-5 rounded-2.5xl bg-gray-10">
            <h2 className="font-bold text-xl">
              Cannot connect to messenger service.
            </h2>
            <p>
              Please try to relogin. If this error occurs again contact the
              support.
            </p>

            <ErrorDisclosure error={context.stateInfo} />
          </div>
        );

      default:
        return undefined;
    }
  }

  return (
    <div className="h-screen -mt-16 pt-24 flex flex-col gap-7 px-12 py-7.5">
      <MessengerActiveRoomContext.Provider value={activeRoomContext}>
        {renderMessengerState()}
      </MessengerActiveRoomContext.Provider>
    </div>
  );
}

function ErrorDisclosure({ error }) {
  const errorString = JSON.stringify(error, null, 2);
  return (
    <Disclosure>
      {({ open, close }) => (
        <>
          {!open && (
            <Disclosure.Button className="p-0 text-sm text-midnight-60 hover:text-midnight-100 focus-visible:text-midnight-100">
              <span>Show error?</span>
            </Disclosure.Button>
          )}
          <Disclosure.Panel className="relative max-w-lg w-full text-xs bg-midnight-20 rounded">
            <div className="absolute right-4 px-1 py-0.5 flex items-center justify-end gap-2">
              <button
                type="button"
                className="p-0 flex items-center gap-0.5 text-xs text-midnight-60 hover:text-midnight-100 focus-visible:text-midnight-100"
              >
                <ClipboardDocumentIcon
                  className="w-3"
                  aria-hidden="true"
                  onClick={() => navigator.clipboard.writeText(errorString)}
                />
                Copy
              </button>
              <button
                type="button"
                className="p-0 flex items-center gap-0.5 text-xs text-midnight-60 hover:text-midnight-100 focus-visible:text-midnight-100"
                onClick={close}
              >
                <EyeSlashIcon className="w-3" aria-hidden="true" />
                Hide
              </button>
            </div>
            <pre className="px-3 py-1 overflow-auto max-h-[24rem] text-midnight-80 hover:text-midnight-100">
              {errorString}
            </pre>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
