import { MessengerContext, useMessengerContext } from '@/contexts';
import { AuthGuard } from '@/router/guards';
import { Outlet } from 'react-router-dom';

export default function DashboardScene() {
  const context = useMessengerContext();

  return (
    <AuthGuard>
      <MessengerContext.Provider value={context}>
        <Outlet />
      </MessengerContext.Provider>
    </AuthGuard>
  );
}
