import { AppClientContext, AppContext } from '@/contexts';
import {
  DiscountIcon,
  DownloadIcon,
  EnvelopeIcon,
  InfoIcon,
  PersonIcon,
  SessionsIcon,
} from '@/icons';
import AppHeader from '@components/AppHeader';
import { Drawer, DrawerItem } from '@components/Drawer';
import { useWindowDimensions } from '@hooks';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function AppLayout() {
  const { width } = useWindowDimensions();
  const mobileDrawerBehaviour = width <= 1024;

  const [drawerShow, setDrawerShow] = useState(!mobileDrawerBehaviour);
  const toogleDrawerShow = () => setDrawerShow(!drawerShow);

  return (
    <AppContext.Provider value={AppClientContext}>
      <div className="flex min-h-screen">
        <Drawer show={drawerShow} onShowChange={toogleDrawerShow}>
          <DrawerItem
            to="messenger"
            iconComponent={<EnvelopeIcon />}
            text="Messages"
          />
          <DrawerItem
            to="workspaces"
            iconComponent={<DownloadIcon />}
            text="Workspaces"
          />
          <DrawerItem
            to="acquisitions"
            iconComponent={<DiscountIcon />}
            text="Acquisitions"
          />
          <DrawerItem
            to="sessions"
            iconComponent={<SessionsIcon />}
            text="Sessions"
          />
          <DrawerItem
            to="coaches"
            iconComponent={<PersonIcon />}
            text="Coaches"
          />
          <div className="grow">{/* Spacer */}</div>
          <DrawerItem
            to="customer_support"
            iconComponent={<InfoIcon />}
            text="Customer Support"
          />
        </Drawer>

        <div className="flex flex-col flex-grow bg-midnight-10 ">
          <AppHeader
            showBurger={!drawerShow}
            onBurgerClick={toogleDrawerShow}
          />
          <main className="flex-grow">
            <Outlet />
          </main>
        </div>
      </div>
    </AppContext.Provider>
  );
}
