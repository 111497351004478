import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { MessengerContext } from '@/contexts/MessengerContext';
import { ChevronDownIcon, ChevronRightIcon, SpinnerIcon } from '@/icons';
import { ClientEvent, SyncState } from '@utils/matrixClient';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import RoomAvatar from './RoomAvatar';

/**
 * @typedef {object} RoomGroupData
 * @prop {string} key
 * @prop {string} title
 * @prop {import("matrix-js-sdk").Room[]} rooms
 */

// TODO: Keyboard navigation

export function RoomList({ search = '' }) {
  console.debug('[RoomList] <render call>');

  const { matrixClient } = useContext(MessengerContext);
  /** @type {ReturnType<typeof useState<RoomGroupData[]>>} */
  const [roomGroups, setRoomGroups] = useState([]);
  const [isPrepared, setIsPrepared] = useState(
    matrixClient.getSyncState() !== null,
  );

  /** @type {ReturnType<typeof useMemo<RoomGroupData[]>>}>} */
  const filteredGroups = useMemo(() => {
    const searchValue = search.toLocaleLowerCase().replace(/\s+/g, '');
    if (searchValue == '') return roomGroups;

    return roomGroups.map((group) => {
      return {
        ...group,
        rooms: group.rooms.filter(({ name }) =>
          name.toLocaleLowerCase().includes(searchValue),
        ),
      };
    });
  }, [roomGroups, search]);

  const updateRooms = useCallback(() => {
    const roomList = matrixClient.getRooms();
    const groups = new Map([
      ['invite', { title: 'Invites', rooms: [] }],
      ['knock', { title: 'Requests', rooms: [] }],
      // Joined rooms grouped by origin
      ['room', { title: 'Rooms', rooms: [] }], // plain Matrix rooms
      [
        'whatsapp',
        {
          title: 'Whatsapp',
          icon: '/images/messengers/whatsup.svg',
          rooms: [],
        },
      ],
      [
        'facebook',
        {
          title: 'Facebook',
          icon: '/images/messengers/messenger.svg',
          rooms: [],
        },
      ],
      [
        'telegram',
        {
          title: 'Telegram',
          icon: '/images/messengers/telegram.svg',
          rooms: [],
        },
      ],
      [
        'instagram',
        {
          title: 'Instagram',
          icon: '/images/messengers/instagram.svg',
          rooms: [],
        },
      ],
      [
        'twitter',
        { title: 'Twitter', icon: '/images/messengers/twitter.svg', rooms: [] },
      ],
      [
        'linkedin',
        {
          title: 'LinkedIn',
          icon: '/images/messengers/linkedin.svg',
          rooms: [],
        },
      ],
      // Rooms grouped by membership status
      ['leave', { title: 'Historical', rooms: [] }],
      // Everything that does not fall into groups above
      ['unclassified', { title: 'Other', rooms: [] }],
    ]);

    // Sort rooms by activity from recent to oldest
    roomList.sort(function (a, b) {
      var event_a = a.getLastLiveEvent();
      if (!event_a) return 1;
      var event_b = b.getLastLiveEvent();
      if (!event_b) return -1;

      if (event_a.getTs() > event_b.getTs()) {
        return -1;
      } else if (event_a.getTs() < event_b.getTs()) {
        return 1;
      }
      return 0;
    });

    for (const room of roomList) {
      const myMembershipInRoom = room.getMyMembership();
      switch (myMembershipInRoom) {
        case 'join': {
          let targetGroup = 'room';
          const bridgeEvent = room.currentState.getStateEvents('m.bridge')[0];
          const bridgeBotEvent = room.getAccountData('drreamz.bridge');
          if (bridgeEvent) {
            // This is a "bridged" room
            const protocolId = bridgeEvent.getContent()?.protocol?.id;
            targetGroup = groups.has(protocolId) ? protocolId : 'unclassified';
          } else if (bridgeBotEvent) {
            // This is a room (chat) with bridge bot
            const protocolId = bridgeBotEvent.getContent()?.protocol;
            targetGroup = groups.has(protocolId) ? protocolId : 'unclassified';
          }
          groups.get(targetGroup).rooms.push(room);
          break;
        }
        case 'invite':
        case 'knock':
        case 'leave':
          groups.get(myMembershipInRoom).rooms.push(room);
          break;
        default:
          console.warn('Room with unknown membership "%s"', myMembershipInRoom);
          groups.get('unclassified').rooms.push(room);
          break;
      }
    }

    setRoomGroups(
      Array.from(groups, ([key, group]) => Object.assign(group, { key })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't track matrixClient
  }, []);

  const handleSyncEvent = useCallback(
    /**  @type {import("matrix-js-sdk").ClientEventHandlerMap[ClientEvent.Sync]} */
    (state) => {
      switch (state) {
        case SyncState.Prepared:
          setIsPrepared(true);
        // eslint-disable-next-line no-fallthrough -- fallthrough is intended
        case SyncState.Syncing:
          updateRooms();
          break;
      }
    },
    [updateRooms],
  );

  useEffect(() => {
    console.debug('[RoomList] Bind listeners.');
    matrixClient.on(ClientEvent.Sync, handleSyncEvent);
    return () => {
      console.debug('[RoomList] Unbind listeners.');
      matrixClient.off(ClientEvent.Sync, handleSyncEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- start on first render, stop when leave
  }, [handleSyncEvent]);

  // Show loader until prepared
  if (!isPrepared) {
    return (
      <div className="grow flex items-center justify-center">
        <SpinnerIcon className="fill-midnight-40 animate-spin" />
        <span className="sr-only">Loading list of rooms...</span>
      </div>
    );
  }

  return (
    <div className="overflow-y-auto pt-2" role="tree" aria-label="Rooms">
      {filteredGroups.map(({ key, title, icon, rooms }) => (
        <RoomGroup key={key} title={title} icon={icon} rooms={rooms} />
      ))}
    </div>
  );
}

/**
 * @param {object} params
 * @param {string} params.title
 * @param {string} [params.icon] icon URL
 * @param {import("matrix-js-sdk").Room[]} params.rooms
 * @param {boolean} params.defaultExpanded
 */
function RoomGroup({
  title = 'Untitled group',
  icon,
  rooms,
  defaultExpanded = true,
}) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const id = useId();
  const groupHeaderId = useMemo(() => `${id}-group-header`, [id]);
  const groupId = useMemo(() => `${id}-room-group`, [id]);

  const unreadNotificationCountInGroup = rooms.reduce(
    (count, room) => count + room.getUnreadNotificationCount(),
    0,
  );

  // Do not render the group if empty
  if (rooms.length == 0) return;

  return (
    <div
      id={groupId}
      role="group"
      aria-labelledby={groupHeaderId}
      aria-expanded={isExpanded}
    >
      <div
        id={groupHeaderId}
        className="flex items-center gap-1 m-1 px-1 rounded text-midnight-60 cursor-pointer select-none hover:text-midnight-80 focus:outline-none focus-visible:text-midnight-80 focus-visible:ring focus-visible:ring-orange-50"
        role="presentation"
        // TODO: Set tabindex to 0 only for current (selected) item
        tabIndex={0}
        onClick={() => setIsExpanded((v) => !v)}
      >
        {isExpanded ? (
          <ChevronDownIcon
            className="w-5 h-5 fill-current"
            aria-hidden="true"
          />
        ) : (
          <ChevronRightIcon
            className="w-5 h-5 fill-current"
            aria-hidden="true"
          />
        )}
        <div className="grow flex items-center gap-1">
          <span className="text-sm font-bold text-current">{title}</span>
          {icon && (
            <img
              src={icon}
              className="shrink-0 w-6 h-6 rounded-full"
              aria-hidden="true"
            />
          )}
        </div>
        {unreadNotificationCountInGroup > 0 && (
          <span className="min-w-[1rem] flex place-content-center px-1 text-xs leading-4 text-white rounded-full bg-midnight-60">
            {unreadNotificationCountInGroup}
          </span>
        )}
      </div>

      {isExpanded &&
        rooms.map((room) => <RoomItem key={room.roomId} room={room} />)}
    </div>
  );
}

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").Room} params.room
 */
function RoomItem({ room }) {
  const activeRoomContext = useContext(MessengerActiveRoomContext);

  const isActiveRoom = useMemo(
    () => activeRoomContext.roomId == room.roomId,
    [activeRoomContext.roomId, room.roomId],
  );

  const membersCount = room.getInvitedAndJoinedMemberCount();
  const latestEvent = room.getLastLiveEvent();
  const unreadNotificationCount = room.getUnreadNotificationCount();
  const latestEventDt = latestEvent
    ? DateTime.fromMillis(latestEvent.localTimestamp)
    : null;
  const formatting = latestEventDt
    ? DateTime.now().hasSame(latestEventDt, 'day')
      ? DateTime.TIME_SIMPLE
      : DateTime.DATE_SHORT
    : null;

  const latestEventPreview = useMemo(() => {
    if (!latestEvent) return;
    const eventType = latestEvent.getType();
    const eventContent = latestEvent.getContent();
    switch (eventType) {
      case 'm.room.create':
        return ''; // Ignore event
      case 'm.room.message':
        return eventContent.body;
      case 'm.room.member': {
        return `${eventContent.displayname} ${eventContent.membership}`;
      }
      case 'm.room.name':
        return 'Change room name';
      case 'm.room.avatar':
        return 'Change room avatar';
      case 'm.room.encrypted':
        return 'Encrypted message';
      case 'm.room.power_levels':
        return 'Change power levels';
      case 'm.room.join_rules':
        return 'Change join rules';
      case 'm.room.history_visibility':
        return 'Change history visibility';
      case 'm.room.guest_access':
        return 'Change guest access';
      case 'm.reaction':
        return 'Reaction';
      default:
        return eventType;
    }
  }, [latestEvent]);

  const handleClick = useCallback(() => {
    activeRoomContext.setRoom(room.roomId);
  }, [activeRoomContext, room.roomId]);

  return (
    // TODO: Add aria-selected for selected room
    <div role="treeitem" aria-selected={isActiveRoom}>
      <button
        className={classNames(
          'group relative w-full flex items-start gap-3 px-8 py-4 rounded-none hover:bg-midnight-10 focus-visible:bg-midnight-10 font-normal text-left',
          {
            'bg-midnight-20': isActiveRoom,
          },
        )}
        onClick={handleClick}
      >
        <RoomAvatar room={room} />
        <div className="grow flex flex-col overflow-hidden">
          <div className="flex items-center gap-3 overflow-hidden">
            <span className="grow font-bold leading-6 truncate">
              {room.name}
            </span>
            {latestEvent && (
              <time
                dateTime={latestEventDt.toISO()}
                className="text-sm text-midnight-50 leading-6 whitespace-nowrap"
              >
                {latestEventDt.toLocaleString(formatting)}
              </time>
            )}
            {unreadNotificationCount > 0 && (
              <span className="absolute right-1.5 min-w-[1rem] flex place-content-center px-1 text-xs leading-4 text-white rounded-full bg-orange-50">
                {unreadNotificationCount}
              </span>
            )}
          </div>
          {latestEventPreview && (
            <span className="text-sm text-midnight-60 truncate">
              {membersCount > 2 && (
                <span className="text-midnight-80">
                  {latestEvent.sender.name}:{' '}
                </span>
              )}
              {latestEventPreview}
            </span>
          )}
        </div>
      </button>
    </div>
  );
}
