import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export default function DrawerItem({
  to,
  iconComponent,
  text,
  className,
  disabled,
}) {
  return (
    <li className={className}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(
            { 'bg-gray-700': isActive },
            disabled
              ? 'text-midnight-70'
              : 'text-midnight-10 hover:bg-gray-700',
            'group flex justify-start gap-3 p-3 rounded-full',
          )
        }
        // Disable NavLink
        onClick={(event) => disabled && event.preventDefault()}
        tabIndex={disabled && -1}
      >
        {({ isActive }) => (
          <>
            <div
              className={
                !disabled
                  ? isActive
                    ? 'fill-midnight-10'
                    : 'fill-midnight-60 group-hover:fill-midnight-10'
                  : 'fill-midnight-60'
              }
            >
              {iconComponent}
            </div>
            <span>{text}</span>
          </>
        )}
      </NavLink>
    </li>
  );
}
