import '@/assets/styles/audio-track.css';
import { MessengerContext } from '@/contexts/MessengerContext';
import { useAudio } from '@/hooks';
import { PaperIcon } from '@/icons';
import { humanFileSize } from '@/utils';
import classNames from 'classnames';
import { DateTime, Duration } from 'luxon';
import { MsgType } from 'matrix-js-sdk';
import { useContext, useState } from 'react';
import ImageThumbnail from './ImageThumbnail';
import './styles.matrix-html-formatted.css';

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").MatrixEvent} params.event
 */
export default function RoomMessageEvent({ event }) {
  const messengerContext = useContext(MessengerContext);
  const content = event.getContent();
  const eventDt = DateTime.fromMillis(event.localTimestamp);

  function handleDoubleClick(e) {
    if (e.ctrlKey) {
      if (e.altKey) {
        console.log(event);
      } else {
        console.log(content);
      }
    }
  }

  return (
    <li
      className={classNames(
        'relative max-w-full p-3 pt-1.5 text-sm rounded-xl rounded-bl-sm self-start',
        messengerContext.matrixClient.getUserId() == event.sender.userId
          ? 'bg-midnight-30'
          : 'bg-midnight-20',
      )}
      style={{
        wordBreak: 'break-word',
      }}
      onDoubleClick={handleDoubleClick}
    >
      <span className="block font-semibold text-midnight-80">
        {event.sender.name}
      </span>
      <MessageContent content={content} />
      {!['m.image', 'm.audio', 'm.file'].includes(content.msgtype) && (
        <span
          className="pl-2 h-0 invisible inline-flex text-xs"
          aria-hidden="true"
          aria-live="off"
        >
          {/* timestamp spacer */}
          {eventDt.toLocaleString(DateTime.TIME_SIMPLE)}
        </span>
      )}
      <time
        dateTime={eventDt.toISO()}
        className="absolute bottom-1 right-3 select-none text-xs text-midnight-60"
      >
        {eventDt.toLocaleString(DateTime.TIME_SIMPLE)}
      </time>
    </li>
  );
}

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").IContent} params.content
 */
function MessageContent({ content }) {
  switch (content.msgtype) {
    case MsgType.Image:
      return (
        <a
          href={`${
            process.env.REACT_APP_MATRIX_HOMESERVER
          }/_matrix/media/r0/download/${content.url.substring(6)}`}
          target="_blank"
          rel="noreferrer"
          className="min-w-[15rem] max-w-full inline-flex gap-2"
        >
          <ImageThumbnail
            url={content.url}
            blurhash={content.info?.['xyz.amorgan.blurhash']}
          />
          <span className="flex flex-col justify-evenly overflow-hidden">
            <span className="truncate text-base font-bold">{content.body}</span>
            {content.info?.size && (
              <span className="text-midnight-60">
                {humanFileSize(content.info.size)}
              </span>
            )}
          </span>
        </a>
      );
    case MsgType.Video:
      return (
        <video
          className="rounded-lg"
          src={`${
            process.env.REACT_APP_MATRIX_HOMESERVER
          }/_matrix/media/v3/download/${content.url.substring(
            6,
          )}?allow_redirect=true`}
          title={content.filename || content.body}
          controls
          preload={content.info?.thumbnail_url ? 'none' : 'metadata'}
          poster={
            content.info?.thumbnail_url &&
            `${
              process.env.REACT_APP_MATRIX_HOMESERVER
            }/_matrix/media/v3/download/${content.info.thumbnail_url.substring(
              6,
            )}?allow_redirect=true`
          }
        ></video>
      );
    case MsgType.Audio:
      return <AudioMessageContent content={content} />;
    case MsgType.File:
      return (
        <a
          href={`${
            process.env.REACT_APP_MATRIX_HOMESERVER
          }/_matrix/media/r0/download/${content.url.substring(6)}`}
          target="_blank"
          rel="noreferrer"
          className="min-w-[15rem] inline-flex gap-2"
        >
          <div
            className="flex items-center justify-center rounded-lg w-12 h-12 bg-midnight-40"
            aria-hidden="true"
          >
            <PaperIcon className="w-8 h-8 text-midnight-60" />
          </div>
          <span className="flex flex-col justify-evenly overflow-hidden">
            <span className="truncate text-base font-bold">{content.body}</span>
            <span className="text-midnight-60">
              {humanFileSize(content.info.size)}
            </span>
          </span>
        </a>
      );
    case MsgType.Text:
    case MsgType.Notice:
      if (
        content.format == 'org.matrix.custom.html' &&
        content.formatted_body
      ) {
        return (
          <span
            className="matrix-html-formatted inline-block"
            dangerouslySetInnerHTML={{ __html: content.formatted_body }}
          ></span>
        );
      }
      return <span>{content.body}</span>;
    default:
      return <span>{content.body}</span>;
  }
}

function AudioMessageContent({ content }) {
  const dur = Duration.fromMillis(content.info?.duration).rescale();
  const [currentProgress, setCurrentProgress] = useState(0);
  const audio = useAudio(
    `${
      process.env.REACT_APP_MATRIX_HOMESERVER
    }/_matrix/media/r0/download/${content.url.substring(6)}`,
    {
      onTimeUpdate: function (event) {
        setCurrentProgress(event.target.currentTime / event.target.duration);
      },
    },
  );

  function togglePlay() {
    if (audio.isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  }

  function handleChangeProgress(event) {
    audio.setProgress(event.target.value);
  }

  return (
    <div>
      <div className="flex items-start gap-2">
        <button
          type="button"
          className="p-2.5 rounded-full bg-midnight-40 text-midnight-60"
          onClick={togglePlay}
        >
          {audio.isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 5.25v13.5m-7.5-13.5v13.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
              />
            </svg>
          )}
        </button>

        <div className="flex flex-col justify-evenly">
          <span className="truncate text-base font-bold">{content.body}</span>

          <input
            className="audio-track mt-2 mb-1"
            type="range"
            tabIndex="-1"
            min="0"
            max="1"
            value={currentProgress}
            onChange={handleChangeProgress}
            step="0.001"
            aria-label="Audio progress bar"
          ></input>

          {content.info && (
            <div className="flex gap-3 text-midnight-60">
              <time dateTime={dur.toISO()}>{dur.toFormat('hh:mm:ss')}</time>
              <span>{humanFileSize(content.info.size)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
